import React from "react"

import ProductView from "../components/product/product-view/product-view"
import { ProductsDatabase } from "../components/product/products-database"
import useProductParameterGroups from "../hooks/useProductParameterGroups"
import useProducts from "../hooks/useProducts"
import { useTranslation } from "../i18n/translate"

const ProductsByMarket = ({ pageContext, location, path }) => {
  const { market } = pageContext
  const productParamGroups = useProductParameterGroups()
  productParamGroups.forEach((group) => {
    // make Product Group initially expanded
    if (group.strapiId === 13) {
      group.initialShown = true
    }
  })
  const productParameterGroupsForFilter = productParamGroups.concat([
    {
      name: { key: "BRANDS-BRAND" },
      info: { key: "PRODUCTS-PRODUCT_FILTERS-BRAND-INFO" },
      prefix: null,
      strapiId: -1,
      suffix: null,
      type: "ENUM",
    },
  ])

  const products = useProducts(true).filter((product) => {
    return product.hasMarket(market.url)
  })

  const db = new ProductsDatabase(products, productParameterGroupsForFilter, [
    market,
  ])

  const { t } = useTranslation()

  return (
    <>
      <ProductView
        filterKey={"market_" + market.url}
        db={db}
        descriptionTranslatedString={t("PRODUCTS-META_DESCRIPTION")}
        titleTranslatedString={t(market?.name?.key)}
        productParamGroups={productParamGroups}
        location={location}
        market={market.id}
        path={path}
      />
    </>
  )
}

export default ProductsByMarket
